body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0;
  font-family: PingFangSC-Regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  padding: 0;
  margin: 0;
}

#root {
  background: #ffffff;
}

/* 超出显示... */

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 两行文本显示... */

.text-overflow-two {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/*  设置背景图的到时候，background默认样式，注意要使用 backgroundImage: url(); 设置图片 */

.backGround-currency {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/* 滤镜置灰 */

.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}

.viewer-image-container {
  touch-action: none;
}

.am-pull-to-refresh-content {
  margin-top: -2.666667rem;
}

.am-pull-to-refresh-indicator {
  font-size: 0 !important;
  padding-bottom: 0.4rem;
  height: 2.666667rem !important;
  background-image: url("./assets/8eb86c75-f15f-4242-86b6-257a9db912c8.gif");
  background-size: 4rem 2.266667rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-sizing: border-box;
}

.am-icon-md {
  width: 0 !important;
  height: 0 !important;
}

.quanju-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  background-image: url("./assets/8eb86c75-f15f-4242-86b6-257a9db912c8.gif");
  background-size: 4rem 2.266667rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .goods-toolbar {
    padding-bottom: 0.906667rem !important;
    height: auto;
  }

  #pagesHome {
    padding-bottom: 0.906667rem !important;
  }

  .irre-ios {
    padding-bottom: 1.6rem !important;
  }

  .refund-submit {
    bottom: 1.066667rem !important;
  }
}